<template>
  <div>
    <TitleBar
      :top-title="title"
      :top-left="'back'"
      :continual="false"
    />
    <div class="flex_scroll">
      <div style="position:relative; width:320px; margin:0 auto">
        <div style="margin-top:40px; text-align:center">
          <input
            v-model="form.uid"
            type="text"
            :placeholder="getLang('3D 모델의 CUID 입력')"
            style="text-align:center; font-size:15px; font-weight:bold; letter-spacing:2px"
            @keyup.enter="onClickSearch"
          >&nbsp;
          <input
            type="button"
            :value="getLang('조회')"
            style="font-size:15px"
            @click="onClickSearch"
          >
        </div>
        <div style="padding:20px; color:#999">
          {{ getLang('www.3dbank.xyz에서 콘텐츠를 검색하여 해당 콘텐츠의 cuid 값 입력.') }}<br>
          <br>
          ({{ getLang('예') }}) view_contents.jsp?cuid=1000915&...
          <br>=> <b>1000915</b><br>
        </div>
        <div
          ref="viewer"
          :style="isShowViewer || 'opacity:0.2'"
        >
          <div style="margin-top:40px; font-size:15px; text-align:center">
            {{ form.conts_name }}
          </div>
          <div
            id="viewer"
            style="width:100%; height:250px; margin:5px auto; border:1px solid #bbb; background-color:#fff"
          />
          <div style="margin-top:40px; text-align:center">
            <input
              type="button"
              :value="getLang('다운로드')"
              style="font-size:15px"
              @click="onClickDownload"
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="is_progress"
      style="position:fixed; inset:0; background-color:#ffffffbb; background:url(/img/ico/progress.gif) center / 70px #ffffffbb no-repeat; z-index:99"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TitleBar from '@/components/navi/TitleBar.vue';
import axios from 'axios';

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    TitleBar,
  },
  data () {
    return {
      form: {
        uid: '',
        conts_name: '',
        conts_descr: '',
      },
      is_progress: false,
      isShowViewer: false,
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang',
    }),
    title () {
      return this.getLang('3DBANK에서 다운로드');
    },
  },
  beforeDestroy () {
    this.cleanViewer();
  },
  mounted () {
  },
  methods: {
    clearForm () {
      this.isShowViewer = false;
      Object.keys(this.form).forEach(key => {
        if (key != 'uid') {
          this.form[key] = '';
        }
      });
    },
    cleanViewer () {
      if (this.viewer) {
        this.viewer.clean();
        this.viewer = null;
      }
    },
    onClickSearch () {
      if (!this.form.uid) {
        this.showAlert(this.title, this.getLang('3D 모델의 CUID를 입력하세요.'));
        return;
      }

      this.clearForm();
      this.cleanViewer();
      this.$nextTick(async () => {
        this.viewer = new window.THREEDBANK.viewer('viewer', {
          host: '3dbank',
          srchost: '//3dbank.xyz/asia3d/3drealplus/5.0.1/release/',
          localpath: undefined,
          toolbar: false,
          rotateSpeed: 1,
          volume: 0,
        });
        await this.viewer.open(this.form.uid, { autoplay: false });
        const inform = this.viewer.arrInform[0];
        if (inform.url_conts) {
          this.isShowViewer = true;
          Object.assign(this.form, {
            conts_name: inform.name,
            conts_descr: inform.descr,
          });
        } else {
          this.showAlert(this.title, this.getLang('찾을 수 없습니다.'));
        }
      });
    },
    async onClickDownload () {
      if (this.isShowViewer) {
        this.is_progress = true;

        await axios.post(`${this.$store.state.apiHost}/api/copyFrom3dbank`, this.form);
        this.is_progress = false;
        this.showAlert(this.title, this.getLang('저장 되었습니다.'));
      }
    },
  }
};
</script>

<style scoped>
</style>
